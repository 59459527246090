var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.title,"modal-class":"modal-primary","hide-footer":"","size":"lg","loading":""},on:{"hidden":_vm.close},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(true)?_c('validation-observer',{ref:"checkForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"xl":"8","lg":"8","md":"8","sm":"8","xs":"8","cols":"8"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Cliente")])]),_c('validation-provider',{attrs:{"name":"document_type","vid":"document_type","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","options":_vm.clients,"reduce":function (val) { return val.id; },"label":"name","hint":"Seleccione un cliente","disabled":_vm.disableAll},model:{value:(_vm.testimony.client),callback:function ($$v) {_vm.$set(_vm.testimony, "client", $$v)},expression:"testimony.client"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"xl":"4","lg":"4","md":"4","sm":"4","xs":"4","cols":"4"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Reserva")])]),_c('validation-provider',{attrs:{"name":"reserve","vid":"reserve","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","options":_vm.reserves,"reduce":function (val) { return val.id; },"label":"name","hint":"Seleccione una reserva","disabled":_vm.disableAll},model:{value:(_vm.testimony.reserve),callback:function ($$v) {_vm.$set(_vm.testimony, "reserve", $$v)},expression:"testimony.reserve"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.rooms,"fields":_vm.fields}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción")])]),_c('validation-provider',{attrs:{"name":"description","vid":"description","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-textarea',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese la descripción de la categoria","disabled":_vm.disableAll,"rows":3},model:{value:(_vm.testimony.description),callback:function ($$v) {_vm.$set(_vm.testimony, "description", $$v)},expression:"testimony.description"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mx-1",attrs:{"type":"submit","variant":"light","disabled":_vm.disableAll},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),(!_vm.loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.disableAll},on:{"click":_vm.registerTestimony}},[_vm._v(" Guardar ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)])],1)]}}],null,false,2858299066)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }